<template>
  <path
    class="cls-1"
    d="M1524.45,1204.66c-3.42-11.06-5.08-22.85-5.94-34.35-1.75-23.4,1.09-47.95,12.56-68.77,12.21-22.16,34.53-37.75,57.78-46.69,21.47-8.25,45.62-11.08,68.01-4.95,60.54,16.58,72.33,90.85,58.33,143.67-3.49,13.17-8.59,26.03-15.56,37.77-4.75,8-10.62,16.4-18.49,21.64-4.48,2.98-10.13,3.82-15.33,4.82-7.39,1.42-14.87,2.41-22.37,3.02-39.98,3.29-87.61-3.95-111.06-40.43-3.29-5.12-5.94-10.59-8.1-16.28-1.02-2.69-2.36-.24-1.65,1.61,8.34,21.97,24.42,39.09,45.81,48.79,19.46,8.83,41.29,11.48,62.49,11.1,15.01-.27,30.4-1.84,44.95-5.72,7.54-2.01,13.28-7.99,17.98-13.93,7.82-9.86,13.64-21.32,18.12-33.03,11.86-31.05,14.42-65.78,6.15-98.05-6.57-25.65-20.41-49.44-44.38-62.2-20.72-11.03-45.23-12.46-67.87-7.44-24.21,5.36-48.64,18.13-65.29,36.75-28.57,31.95-29.39,81.03-18.89,120.53,.24,.89,.48,1.78,.75,2.66,.75,2.41,2.78,1.93,2.02-.54Z"
  />
  <path
    class="cls-1"
    d="M1698.72,1157.95c-1.96,7.61-5.14,15.03-8.61,22.04-7.19,14.51-17.08,28.16-30.45,37.56-16.08,11.31-36.26,13.99-55.43,11.41-18.79-2.53-37.59-10.55-50.41-24.87-29.91-33.42-8.05-83.46,19.81-110.6,10.83-10.55,24.28-20.47,39.13-24.39,4.29-1.13,8.36-1.5,12.62-.17,4.63,1.45,9.15,3.27,13.58,5.24,28.09,12.51,59.47,35.55,60.91,69.14,.21,4.92-.24,9.81-1.15,14.64-.24,1.29,1.77,4.83,2.21,2.53,3.48-18.42-.77-36.76-11.67-51.97-9.88-13.79-23.85-24.32-38.62-32.41-5.92-3.24-12.05-6.14-18.34-8.61-3.78-1.49-7.94-3.4-12.05-3.61-5.7-.29-11.5,1.72-16.66,3.95-7.32,3.16-14.17,7.44-20.5,12.27-16.56,12.64-30.01,29.66-38.08,48.9-8.32,19.83-11.5,42.6-2.19,62.67,8.16,17.58,24.09,30,42.05,36.54,18.83,6.86,41.66,8.4,60.69,1.44,28.34-10.37,46.54-39.5,54.82-67.3,.19-.62,.37-1.25,.53-1.87,.33-1.29-1.61-4.83-2.21-2.53Z"
  />
  <path
    class="cls-1"
    d="M1656.64,1114.97c3.11,4.2,6.74,11.08,9.03,16.62,4.91,11.86,7.3,25.24,3.48,37.76-7.05,23.07-33.28,42.74-57.74,40.03-28.68-3.17-43.76-35.46-44.74-61.29-.35-9.26,.44-19.77,4.97-28.06,3.01-5.51,9.82-8.3,15.22-10.89,22.5-10.79,51.7-15.31,69.77,5.84,1.52,1.78,1.5-2.29,.65-3.29-8.73-10.23-20.77-15.75-34.2-15.95-11.58-.17-23.04,2.99-33.59,7.58-4,1.74-7.9,3.71-11.66,5.91-2.49,1.46-5,2.87-6.74,5.24-5.52,7.54-6.63,18.58-6.77,27.6-.23,14.1,2.95,28.32,9.39,40.87,6.45,12.6,16.32,24.3,30.15,28.82,25.56,8.35,53.31-10.05,64.59-32.5,10.8-21.5,2.43-49.16-11.17-67.57-1.37-1.85-1.42,2.25-.65,3.29Z"
  />

  <path
    class="cls-1"
    d="M844.87,87.74c7.66-2.37,15.84-3.52,23.81-4.12,16.22-1.21,33.24,.75,47.66,8.7,15.36,8.46,26.16,23.93,32.36,40.05,5.72,14.88,7.68,31.62,3.43,47.13-11.49,41.95-62.97,50.13-99.57,40.42-9.13-2.42-18.04-5.95-26.17-10.78-5.55-3.29-11.36-7.36-15-12.82-2.07-3.11-2.65-7.02-3.34-10.62-.99-5.12-1.67-10.31-2.1-15.5-2.28-27.71,2.74-60.72,28.02-76.97,3.55-2.28,7.34-4.12,11.28-5.62,1.86-.71,.16-1.63-1.12-1.14-15.22,5.78-27.09,16.92-33.82,31.75-6.12,13.49-7.96,28.62-7.69,43.31,.19,10.4,1.28,21.07,3.96,31.15,1.39,5.23,5.54,9.2,9.65,12.46,6.83,5.42,14.77,9.46,22.89,12.56,21.52,8.22,45.59,9.99,67.96,4.26,17.78-4.55,34.26-14.15,43.11-30.76,7.64-14.36,8.63-31.35,5.16-47.04-3.72-16.78-12.56-33.71-25.47-45.25-22.14-19.8-56.16-20.37-83.53-13.09-.62,.16-1.23,.33-1.84,.52-1.67,.52-1.34,1.93,.37,1.4Z"
  />
  <path
    class="cls-1"
    d="M877.24,208.52c-5.27-1.36-10.42-3.56-15.28-5.97-10.06-4.98-19.51-11.84-26.03-21.1-7.84-11.14-9.69-25.13-7.91-38.41,1.75-13.03,7.31-26.05,17.24-34.93,23.16-20.73,57.84-5.58,76.66,13.73,7.31,7.51,14.19,16.83,16.9,27.12,.79,2.98,1.04,5.79,.12,8.75-1.01,3.21-2.26,6.34-3.63,9.41-8.67,19.47-24.64,41.22-47.92,42.21-3.41,.15-6.8-.17-10.15-.8-.89-.17-3.35,1.23-1.75,1.53,12.77,2.41,25.48-.53,36.02-8.09,9.55-6.85,16.86-16.53,22.46-26.76,2.25-4.11,4.25-8.35,5.97-12.71,1.03-2.62,2.35-5.51,2.5-8.35,.2-3.95-1.19-7.97-2.74-11.55-2.19-5.07-5.16-9.82-8.5-14.2-8.76-11.48-20.56-20.8-33.89-26.39-13.74-5.76-29.52-7.97-43.43-1.52-12.18,5.65-20.79,16.69-25.32,29.14-4.75,13.05-5.82,28.87-1,42.06,7.18,19.64,27.38,32.25,46.64,37.99,.43,.13,.86,.26,1.3,.37,.89,.23,3.35-1.12,1.75-1.53Z"
  />
  <path
    class="cls-1"
    d="M907.03,179.35c-2.91,2.15-7.68,4.67-11.52,6.26-8.22,3.4-17.49,5.06-26.17,2.41-15.99-4.88-29.62-23.07-27.74-40.02,2.2-19.88,24.58-30.33,42.48-31,6.42-.24,13.7,.31,19.45,3.45,3.82,2.09,5.75,6.8,7.55,10.55,7.48,15.6,10.61,35.83-4.05,48.35-1.24,1.05,1.59,1.04,2.28,.45,7.09-6.05,10.92-14.39,11.05-23.7,.12-8.02-2.07-15.97-5.25-23.28-1.21-2.77-2.57-5.47-4.1-8.08-1.01-1.73-1.99-3.47-3.63-4.67-5.22-3.82-12.88-4.59-19.13-4.69-9.77-.16-19.63,2.05-28.33,6.5-8.73,4.47-16.84,11.31-19.97,20.9-5.78,17.71,6.96,36.95,22.53,44.76,14.9,7.48,34.07,1.68,46.83-7.74,1.28-.95-1.56-.98-2.28-.45Z"
  />

  <path
    class="cls-1"
    d="M356.93,1145.11c-2.77,5.19,7.29,8.79,12.64,3.44,5.35-5.34-5.5-16.84-12.64-3.44Z"
  />
  <path
    class="cls-1"
    d="M368.76,1101.03c-.4,6.67,13.17,9.32,15.87-.62,2.7-9.94-14.83-16.68-15.87,.62Z"
  />
  <path
    class="cls-1"
    d="M284.49,1171.89c3.62,7.57,10.3,6.62,18.42,2.36,8.12-4.26-1.99-16.33-9.47-15.26-7.47,1.07-10.97,8.67-8.95,12.9Z"
  />
  <path
    class="cls-1"
    d="M248.5,1154.57c5.44-3.29,14.95-14.26,5.8-20.47-9.15-6.22-24.5,10.22-21.61,17.8,2.89,7.6,11.33,5.39,15.81,2.67Z"
  />
  <path
    class="cls-1"
    d="M308.82,1028.86c4.2,8.78,11.95,7.68,21.38,2.73,9.42-4.94-2.31-18.95-10.99-17.71-8.67,1.24-12.73,10.06-10.38,14.97Z"
  />
  <path
    class="cls-1"
    d="M268.62,1090.15c-4.88,5.39-1.86,17.65,5.42,21.57,7.28,3.93,26.8-13.53,21.25-22.83-5.56-9.3-17.79-8.53-26.66,1.26Z"
  />
  <path
    class="cls-1"
    d="M340.92,1072.62c-3.38,6.33,8.9,10.73,15.43,4.21,6.52-6.53-6.71-20.56-15.43-4.21Z"
  />
  <path
    class="cls-1"
    d="M310.29,1121.16c-.4,6.67,13.17,9.32,15.87-.62,2.71-9.94-14.83-16.67-15.87,.62Z"
  />

  <path
    class="cls-1"
    d="M1846.83,167.99c-4.01-4.16-10.43,4.15-7.06,10.78,3.37,6.63,17.42-.04,7.06-10.78Z"
  />
  <path
    class="cls-1"
    d="M1884.48,192.44c-6.12-2.39-12.71,9.49-4.24,15.04,8.48,5.54,20.1-8.81,4.24-15.04Z"
  />
  <path
    class="cls-1"
    d="M1843.76,92.1c-8.18,1.09-9.32,7.63-7.79,16.52,1.52,8.89,15.89,3.09,17.15-4.23,1.26-7.31-4.79-12.89-9.36-12.29Z"
  />
  <path
    class="cls-1"
    d="M1870.88,63.69c1.43,6.09,8.8,18.3,17.39,11.64s-2.13-26.01-10.1-25.62c-7.98,.4-8.48,8.96-7.29,13.99Z"
  />
  <path
    class="cls-1"
    d="M1970.18,158.26c-9.49,1.26-10.81,8.86-9.04,19.17,1.77,10.32,18.43,3.59,19.9-4.9,1.47-8.5-5.55-14.97-10.86-14.26Z"
  />
  <path
    class="cls-1"
    d="M1925.04,102.06c-3.56-6.21-15.94-7.1-21.82-1.48-5.88,5.61,4.53,29.18,14.91,26.8,10.38-2.38,13.38-14.05,6.91-25.32Z"
  />
  <path
    class="cls-1"
    d="M1919.49,175.01c-4.9-5.08-12.74,5.07-8.62,13.16,4.13,8.09,21.27-.05,8.62-13.16Z"
  />
  <path
    class="cls-1"
    d="M1883.38,131.62c-6.12-2.4-12.72,9.49-4.24,15.03,8.48,5.55,20.1-8.81,4.24-15.03Z"
  />
</template>
<script>
export default {
  name: "svg circulos",
};
</script>